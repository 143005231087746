<template>
	<div class="balances">
		<div class="balances__item">
			<img class="balances__item__icon" :src="require('../../assets/icons/wallet.svg')" alt="" />
			<span class="balances__item__label">{{ $t('withdrawal.withdrawal-balance') }}</span>
			<span class="balances__item__value">
				{{ formatCurrency($i18n.locale, currency, maxAmount) }}
			</span>
		</div>

		<div class="balances__item">
			<img class="balances__item__icon" :src="require('../../assets/icons/coins-exchange.svg')" alt="" />
			<span class="balances__item__label">{{ $t('withdrawal.bonus-balance') }}</span>
			<span class="balances__item__value">
				{{ formatCurrency($i18n.locale, currency, bonus) }}
			</span>
		</div>
	</div>
</template>

<script>
export default {
	name: 'WithdrawalStats',
	props: ['currency', 'maxAmount', 'bonus'],
	methods: {
		formatCurrency: (locale, currency, amount) =>
			Intl.NumberFormat(locale, { style: 'currency', currency }).format(amount),
	},
};
</script>

<style lang="scss" scoped>
.balances {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-top: 1.3rem;
	margin-bottom: 2rem;

	&__item {
		width: 100%;
		padding: 1rem;
		background: rgba(42, 46, 49, 0.5);
		border-radius: 5px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 10px;

		&__icon {
			margin-right: 1rem;
		}

		&__label {
			display: block;
			flex: 1;
			text-align: start;
			margin-right: 1rem;
		}

		&__value {
			font-weight: bold;
		}
	}
}
</style>
